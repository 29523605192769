import checkCircle from '@iconify/icons-ant-design/check-circle-filled';
import closeCircle from '@iconify/icons-ant-design/close-circle-filled';
import exclamationCircle from '@iconify/icons-ant-design/exclamation-circle-filled';
import lockOutlined from '@iconify/icons-ant-design/lock-outlined';
import unlockOutlined from '@iconify/icons-ant-design/unlock-outlined';
import classroomIcon from '@iconify/icons-arcticons/classroom';
import buildingMultiple from '@iconify/icons-clarity/building-solid';
import walletIcon from '@iconify/icons-entypo/wallet';
import adminOutlined from '@iconify/icons-eos-icons/admin-outlined';
import successFilled from '@iconify/icons-ep/success-filled';
import editFill from '@iconify/icons-eva/edit-fill';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import searchFill from '@iconify/icons-eva/search-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import chalkboardTeacher from '@iconify/icons-fa-solid/chalkboard-teacher';
import rupeeSign from '@iconify/icons-fa6-solid/rupee-sign';
import gradeList from '@iconify/icons-file-icons/a';
import peopleSearch20Filled from '@iconify/icons-fluent/people-search-20-filled';
import taskList from '@iconify/icons-fluent/text-bullet-list-square-edit-24-filled';
import crossCircle from '@iconify/icons-gridicons/cross-circle';
import outlineAccountCircle from '@iconify/icons-ic/outline-account-circle';
import outlineUploadFile from '@iconify/icons-ic/outline-upload-file';
import roundFileMove from '@iconify/icons-ic/round-drive-file-move';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
import sharpUploadFile from '@iconify/icons-ic/sharp-upload-file';
import clipboardTaskList from '@iconify/icons-icomoon-free/clipboard';
import checkList from '@iconify/icons-icon-park-solid/checklist';
import accountEye from '@iconify/icons-mdi/account-eye';
import atIcon from '@iconify/icons-mdi/at';
import CloudDownloadIcon from '@iconify/icons-mdi/download';
import trainingClass from '@iconify/icons-mdi/google-classroom';
import booksFill from '@iconify/icons-ph/books-fill';
import studentBold from '@iconify/icons-ph/student-bold';
import password1Icon from '@iconify/icons-wpf/password1';
import announcementIcon from '@iconify/icons-zondicons/announcement';

export const ViewIcon = accountEye;
export const ViewIcon2 = eyeFill;
export const EditIcon = editFill;
export const LockIcon = lockOutlined;
export const UnlockIcon = unlockOutlined;
export const PaidIcon = successFilled;
export const UnpaidIcon = crossCircle;
export const DownloadIcon = CloudDownloadIcon;
export const UploadIcon = outlineUploadFile;
export const UploadIcon2 = sharpUploadFile;

export const SearchIcon = searchFill;
export const TrashIcon = trash2Fill;
export const FilterIcon = roundFilterList;
export const MoveIcon = roundFileMove;

export const SuccessDialogIcon = checkCircle;
export const ErrorDialogIcon = closeCircle;
export const WarningDialogIcon = exclamationCircle;
export const CNICIcon = outlineAccountCircle;
export const EmailIcon = atIcon;
export const ShowPasswordIcon = eyeOffFill;
export const HidePasswordIcon = eyeFill;
export const PasswordIcon = password1Icon;

export const AdminsIcon = adminOutlined;
export const StudentsIcon = studentBold;
export const TeachersIcon = chalkboardTeacher;
export const CoordinatorsIcon = peopleFill;
export const CoursesIcon = booksFill;
export const DashboardIcon = pieChart2Fill;
export const FeeIcon = walletIcon;
export const MadarisIcon = buildingMultiple;
export const ClassesIcon = trainingClass;
export const PastClassesIcon = classroomIcon;
export const StudentSearchIcon = peopleSearch20Filled;

export const RupeeIcon = rupeeSign;
export const AssignmentIcon = taskList;
export const GradeIcon = gradeList;
export const MarksIcon = clipboardTaskList;
export const AttendanceIcon = checkList;
export const AnnouncementIcon = announcementIcon;