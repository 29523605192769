export const TITLE = 'ISDP';
export const fullTitle = 'Intensive Software Development Program';
export const defaultPassword = '123456';
export const defaultCoordinatortPassword = defaultPassword;
export const defaultTeacherPassword = defaultPassword;
export const defaultStudentPassword = defaultPassword;
export const rowsPerPageList = [10, 15, 30, 50, 100];
export const defaultPerPage = 10;
export const BackendURL = 'https://isdp.backend.eastdevs.com/';
export const BackendURLAPI = 'https://isdp.backend.eastdevs.com/api/';


export const AdminLoginImage = "/static/illustrations/Admin.png";
export const CoordinatorLoginImage = "/static/illustrations/coordinator.png";
export const TeacherLoginImage = "/static/illustrations/teacher.png";
export const StudentLoginImage = "/static/illustrations/student.png";
export const ForgetPageImage = "/static/illustrations/forgot.png";
export const ResetPageImage = ForgetPageImage;
export const Image404 = "/static/illustrations/404.png";
export const Image401 = "/static/illustrations/401.webp";
export const DefaultAvatar = '/static/mock-images/avatars/islamicavatar.png';
export const DefaultUploadedFileImage = '/static/icons/file.png';
export const NotificationSound = '/static/sounds/notification.mp3';


export const NotificationReload = 1000 * 30;
export const DataReload = NotificationReload;
export const RailFenceSize = 3;

export const allowedExtensions = ['png', 'jpg', 'jpeg', 'mp3', 'pdf', 'zip', 'rar', 'docx', 'doc', 'ppt', 'pptx', 'txt'];

export const acceptImageUpload = "image/png, image/jpg, image/jpeg";
export const acceptFileUpload = "image/png, image/jpg, image/jpeg, audio/mp3, application/pdf, application/zip, application/doc, application/docx, application/ppt, application/pptx, application/txt";
export const maxUploadFileSize = 10 * 1024 * 1024; //10 Mbs
export const hideFileAlertIn = 3000;