export const RouteMadaris = '/admin/madaris';
export const RouteCoordinators = '/admin/coordinators';
export const RouteCourses = '/admin/courses';
export const RouteLandingPage = '/';
export const RouteForgetPass = '/forget';
export const RouteResetPass = '/reset';
export const RouteAdminDashboard = '/admin/dashboard';
export const RouteSearchStudent = '/admin/students/search';
export const RouteSearchTeacher = '/admin/teachers/search';
export const RouteAdminFee = '/admin/fee';
export const RouteAdminSetting = '/admin/settings';
export const RouteAdminProfile = '/admin/profile';


export const RouteCoordinatorSettings = '/coordinator/settings';
export const RouteCoordinatorProfile = '/coordinator/profile';
export const RouteTeachers = '/coordinator/teachers';
export const RouteCoordinatorFee = '/coordinator/fee';

export const RouteTeacherDashboard = '/teacher/dashboard';
export const RoutePastClasses = '/teacher/past';
export const RouteTeacherProfile = '/teacher/profile';
export const RouteTeacherSettings = '/teacher/settings';

export const RouteAdminLogin = '/admin/login';
export const RouteCoordinatorLogin = '/coordinator/login';
export const RouteTeacherLogin = '/teacher/login';
export const RouteStudentLogin = '/student/login';

export const RouteStudentDashboard = '/student/dashboard';
export const RouteStudentResult = '/student/result';
export const RouteStudentProfile = '/student/profile';
export const RouteStudentSettings = '/student/settings';