export const provinces = [
	'Punjab',
	'Khyber_Pakhtunkhwa',
	'Gilgit_Baltistan',
	'Sindh',
	'Federal'
];

export const qualification = [
	'BA',
	'BSc',
	'BBA',
	'BCom',
	'BE',
	'MBBS'
];